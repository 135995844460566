import { useState } from "react";

// ** React import
import { HashRouter, Routes, Route } from "react-router";

import { I18nextProvider } from "react-i18next";

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import { getDesignTokens } from "./themes/themePrimitives";

import IdleFishingLayout from "./layout/IdleFishingLayout";

import IdleFishingHomePage from "./pages/IdleFishingHomePage";
import IdleFishingDocsPage from "./pages/IdleFishingDocsPage";

import i18n from "./i18n";

function App() {
  // Fix i18n not re-render when load language's code from localstorage
  const [_, reload] = useState(false)
  
  return (
    <HashRouter>
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={createTheme(getDesignTokens("light"))}>
          <CssBaseline />
          <Routes>
            <Route path="/" element={<IdleFishingLayout reload={reload} />}>
              <Route path="/" element={<IdleFishingHomePage />} />
              <Route path="/docs" element={<IdleFishingDocsPage />} />
            </Route>
          </Routes>
        </ThemeProvider>
      </I18nextProvider>
    </HashRouter>
  );
}

export default App;
