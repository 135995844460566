// MUI import
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import { useTranslation } from "react-i18next";

// ** Helper Components import
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

// ** Assets import
import { IdleFishingImages } from "../../assets";

import {
  XChannel,
  TelegramAnnouncement,
  TelegramChat,
  SwapLink,
} from "../../const";

// ** Css import
import "./styles.css";

const IdleFishingHomePage = () => {
  const { t } = useTranslation();

  const handleOpenLink = (link: string) => {
    window.open(link, "_blank");
  };

  return (
    <Container maxWidth="sm" className="home-page-container">
      <LazyLoadImage
        effect="blur"
        alt="idle fishing logo"
        src={IdleFishingImages.IdleFishingLogo}
        className="home-page-idle-fishing-logo-image hover-effect"
      />
      <Typography
        className="home-page-introduce-text"
        sx={{
          mt: "1rem",
          fontSize: "1.5rem",
          lineHeight: "2rem",
          fontWeight: 700,
          color: 'white'
        }}
      >
        {t("homePage.introduce")}
      </Typography>
      <Box className="home-page-launched-info-box">
        <Typography
          sx={{
            fontSize: "1.5rem",
            lineHeight: "2rem",
            fontWeight: 700,
            color: 'white'
          }}
        >
          {t("homePage.launchedData")}
        </Typography>
        <Box className="home-page-social-icons-box">
          <Tooltip title={<Typography>{t("homePage.x-channel")}</Typography>}>
            <Box
              className="hover-effect"
              component="div"
              onClick={() => handleOpenLink(XChannel)}
            >
              <LazyLoadImage
                src={IdleFishingImages.XIcon}
                alt="mimi icon"
                effect="blur"
                className="home-page-social-logo-icon"
              />
            </Box>
          </Tooltip>
          <Tooltip
            title={<Typography>{t("homePage.telegram-chat")}</Typography>}
          >
            <Box
              className="hover-effect"
              component="div"
              onClick={() => handleOpenLink(TelegramChat)}
            >
              <LazyLoadImage
                src={IdleFishingImages.TelegramIcon}
                alt="mimi icon"
                effect="blur"
                className="home-page-social-logo-icon"
              />
            </Box>
          </Tooltip>
          <Tooltip
            title={
              <Typography>{t("homePage.telegram-announcement")}</Typography>
            }
          >
            <Box
              className="hover-effect"
              component="div"
              onClick={() => handleOpenLink(TelegramAnnouncement)}
            >
              <LazyLoadImage
                src={IdleFishingImages.TelegramIcon}
                alt="mimi icon"
                effect="blur"
                className="home-page-social-logo-icon"
              />
            </Box>
          </Tooltip>
        </Box>
      </Box>
      <Box className="home-page-btns-group">
        <Box className="home-page-play-btn">
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "1.875rem",
              lineHeight: "2.25rem",
              color: 'white'
            }}
          >
            {t("homePage.coming-soon")}
          </Typography>
        </Box>
        <Box
          className="home-page-swap-btn hover-effect"
          component="div"
          onClick={() => handleOpenLink(SwapLink)}
        >
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "1.875rem",
              lineHeight: "2.25rem",
              color: 'white'
            }}
          >
            {t("homePage.swap")}
          </Typography>
        </Box>
      </Box>
      <Typography
        sx={{
          marginTop: "7rem",
          fontSize: "1.25rem",
          textAlign: "center",
          lineHeight: "1.75rem",
          paddingBottom: "1rem",
        }}
      >
        © Idle Fishing. All Rights Reserved.
      </Typography>
    </Container>
  );
};

export default IdleFishingHomePage;
