import IdleFishingENImage from './en-US.png';
import IdleFishingJAImage from "./ja-JP.png";
import IdleFishingKOImage from "./ko.png";
import IdleFishingNote from "./whitelist_icon_mini.png";


import XIcon from "./icon_x.png";
import IdleBookIcon from "./icon_book.png";
import GreenButton from "./green_button.png";
import TelegramIcon from "./telegram_icon_mini.png"
import IdleFishingLogo from "./idle-fishing-logo.png";
import IdleFishingGetBoardTutorial from "./get-board-tutorial.png";
import IdleFishingEquipBoardTutorial from "./equip-board-tutorial.png";
import IdleFishingTokenomics from "./idle-fishing-tokenomics.png";
import IdleFishingPlayToEarn from "./idle-fishing-play-to-earn.png";
import IdleFishingChestOpen from "./idle-fishing-chest-open.png";
import IdleFishingRarity from "./idle-fishing-chest-rarity.png";
import IdleFishingOverview from "./idle-fishing-overview.png";
import IdleFishingWithdraw from "./withdraw-tutorial.png"

const IdleFishingImages = {
  XIcon,
  IdleBookIcon,
  GreenButton,
  TelegramIcon,
  IdleFishingKOImage,
  IdleFishingENImage,
  IdleFishingJAImage,
  IdleFishingNote,
  
  // New Images
  IdleFishingLogo,
  IdleFishingOverview,
  IdleFishingTokenomics,
  IdleFishingPlayToEarn,
  IdleFishingChestOpen,
  IdleFishingWithdraw,
  IdleFishingRarity,
  IdleFishingGetBoardTutorial,
  IdleFishingEquipBoardTutorial,
};

export { IdleFishingImages };
