// ** react
import { useCallback } from "react";

// ** react-router import
import { Outlet, useNavigate, useLocation } from "react-router";

import { useTranslation } from "react-i18next";

// ** MUI import
import Box from "@mui/material/Box";

// ** Components import
import SwitchLanguage from "../components/SwitchLanguage";

// ** Helper Components import
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import { IdleFishingImages } from "../assets";

// ** Css import
import "./styles.css";

const IdleFishingLayout = ({ reload }: { reload: (v: boolean) => void }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname

  const handleToggleDocs = useCallback(() => {
    if(pathname === '/docs') {
      navigate(-1)
    } else {
      navigate("/docs")
    }
  }, [pathname])

  return (
    <Box className="page-container">
      {/* App bar */}
      <Box className="idle-fishing-app-bar-left-side">
        <Box className="hover-effect" component='div' onClick={() => navigate('/')}>
          <LazyLoadImage
            src={IdleFishingImages.IdleFishingLogo}
            alt="app bar logo"
            effect="blur"
            className="idle-fishing-app-bar-left-side-img"
          />
        </Box>
      </Box>
      <Box className="idle-fishing-app-bar-right-side">
        <Box
          component="div"
          onClick={handleToggleDocs}
          className="hover-effect"
        >
          <img
            src={IdleFishingImages.IdleBookIcon}
            alt="app bar not logo"
            className="idle-fishing-app-bar-right-side-note-logo"
          />
        </Box>
        <SwitchLanguage reload={reload} />
      </Box>
      <Box className="idle-fishing-bg">
        <video
          autoPlay
          muted
          loop
          playsInline
          src="/idle-fishing-bg-audio.mp4"
          className="idle-fishing-video-bg"
        />
        <Box className="idle-fishing-img-bg"></Box>
      </Box>
      <Outlet />
    </Box>
  );
};

export default IdleFishingLayout;
