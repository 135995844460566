// ** React import
import { useMemo, useState, useEffect, useCallback } from "react";

// ** MUI import
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { useTranslation } from "react-i18next";

// ** Const import
import { STORAGE_KEY_LANGUAGE_CODE } from "../../const";

// ** Types import
import { LanguageInfo, SupportLanguage } from "../../types";

// ** Assets import
import { IdleFishingImages } from "../../assets";

// ** Css import
import "./styles.css";

const enLanguage: LanguageInfo = {
  name: "English",
  code: SupportLanguage.EN,
  image: IdleFishingImages.IdleFishingENImage,
};

const koLanguage: LanguageInfo = {
  name: "한국인",
  code: SupportLanguage.KO,
  image: IdleFishingImages.IdleFishingKOImage,
};

const LanguageInfoMap: Record<string, LanguageInfo> = {
  [SupportLanguage.EN]: enLanguage,
  [SupportLanguage.KO]: koLanguage,
};

const SwitchLanguage = ({ reload }: { reload: (v: boolean) => void }) => {
  const [toggleLang, setToggleLang] = useState<string>(SupportLanguage.EN);

  const { i18n } = useTranslation();

  const toggleLangInfo = useMemo(() => {
    const _info = LanguageInfoMap[toggleLang];

    return _info;
  }, [toggleLang]);

  // Load Language From Local Storage
  useEffect(() => {
    const code = localStorage.getItem(STORAGE_KEY_LANGUAGE_CODE);

    if (code) {
      i18n.changeLanguage(code).then(() => {
        if (code === SupportLanguage.EN) {
          setToggleLang(SupportLanguage.KO);
        } else {
          setToggleLang(SupportLanguage.EN);
        }
      });
      reload(true)
    }
  }, []);

  const handleChangeLanguage = useCallback(() => {
    i18n.changeLanguage(toggleLang).then(() => {
      // Save to local storage
      localStorage.setItem(STORAGE_KEY_LANGUAGE_CODE, toggleLang);
      if (toggleLang === SupportLanguage.EN) {
        setToggleLang(SupportLanguage.KO);
      } else {
        setToggleLang(SupportLanguage.EN);
      }
    });
  }, [toggleLang]);

  return (
    <Box
      component="button"
      onClick={handleChangeLanguage}
      className="switch-lang-bnt"
    >
      <img
        src={toggleLangInfo.image}
        alt="lang image"
        className="language-image"
      />
      <Typography className="language-name">{toggleLangInfo.name}</Typography>
    </Box>
  );
};

export default SwitchLanguage;
