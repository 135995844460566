import { useNavigate } from "react-router";

// MUI import
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

// ** Helper Components import
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import { useTranslation, Trans } from "react-i18next";

// ** Assets import
import { IdleFishingImages } from "../../assets";

// Css import
import "./styles.css";

const IdleFishingDocsPage = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  return (
    <Container maxWidth="xl" className="docs-page-container">
      <Box className="docs-page-content-box">
        <Box sx={{ mx: "auto" }} component="div" onClick={() => navigate("/")}>
          <LazyLoadImage
            effect="blur"
            alt="idle fishing"
            src={IdleFishingImages.IdleFishingLogo}
            className="docs-page-idle-fishing-image hover-effect"
          />
        </Box>
        <Typography
          sx={{
            fontSize: "1.25rem",
            lineHeight: "2rem",
          }}
        >
          {t("docsPage.Introduce.segment1", { game: "Idle Fishing" })}
          <br />
          <br />
          {t("docsPage.Introduce.segment2", { game: "Idle Fishing" })}
          <br />
          <br />
          {t("docsPage.Introduce.segment3", { game: "Idle Fishing" })}
          <br />
          <br />
          {t("docsPage.Introduce.segment4", { game: "Idle Fishing" })}
          <br />
          <br />
          {t("docsPage.Introduce.segment5", { game: "Idle Fishing" })}
        </Typography>
      </Box>
      <Divider
        orientation="horizontal"
        variant="middle"
        flexItem
        sx={{ margin: 0, borderWidth: "1px" }}
      />
      <Box className="docs-page-content-box">
        <Typography variant="h1" sx={{ textAlign: "left" }}>
          {t("docsPage.Overview.title")}
        </Typography>
        <Typography
          sx={{
            fontSize: "1.25rem",
            lineHeight: "2rem",
          }}
        >
          {t("docsPage.Overview.content")}
        </Typography>
        <Box sx={{ mx: "auto" }}>
          <LazyLoadImage
            effect="blur"
            alt="idle fishing"
            src={IdleFishingImages.IdleFishingOverview}
            className="docs-page-overview-image hover-effect"
          />
        </Box>
      </Box>
      <Divider
        orientation="horizontal"
        variant="middle"
        flexItem
        sx={{ margin: 0, borderWidth: "1px" }}
      />
      <Box className="docs-page-content-box">
        <Typography variant="h1" sx={{ textAlign: "left" }}>
          {t("docsPage.ChestUpBoatOut.title")}
        </Typography>
        <Typography
          sx={{
            fontSize: "1.25rem",
            lineHeight: "2rem",
          }}
        >
          {t("docsPage.ChestUpBoatOut.contentSeg1")}
        </Typography>
        <Box sx={{ mx: "auto" }}>
          <LazyLoadImage
            src={IdleFishingImages.IdleFishingChestOpen}
            alt="open chest image"
            effect="blur"
            className="docs-page-open-chest-image hover-effect"
          />
        </Box>
        <Typography
          sx={{
            fontSize: "1.25rem",
            lineHeight: "2rem",
          }}
        >
          {t("docsPage.ChestUpBoatOut.contentSeg2")}
        </Typography>
        <Box sx={{ mx: "auto" }}>
          <LazyLoadImage
            src={IdleFishingImages.IdleFishingRarity}
            alt="rarity image"
            effect="blur"
            className="docs-page-chest-rarity-image hover-effect"
          />
        </Box>
        <Typography variant="h6" sx={{ textAlign: "left" }}>
          {t("docsPage.ChestUpBoatOut.rarityTitle")}
        </Typography>
        <ol className="docs-page-disc-list docs-page-mt-[-1rem]">
          <li>
            <span className="docs-page-bold-text text-rarity-1">
              {t("docsPage.ChestUpBoatOut.Common")}
            </span>
            <Trans i18nKey="docsPage.ChestUpBoatOut.Common.info" />
          </li>
          <li>
            <span className="docs-page-bold-text text-rarity-2">
              {t("docsPage.ChestUpBoatOut.Uncommon")}
            </span>
            <Trans i18nKey="docsPage.ChestUpBoatOut.Uncommon.info" />
          </li>
          <li>
            <span className="docs-page-bold-text text-rarity-3">
              {t("docsPage.ChestUpBoatOut.Rare")}
            </span>
            <Trans i18nKey="docsPage.ChestUpBoatOut.Rare.info" />
          </li>
          <li>
            <span className="docs-page-bold-text text-rarity-4">
              {t("docsPage.ChestUpBoatOut.Epic")}
            </span>
            <Trans i18nKey="docsPage.ChestUpBoatOut.Epic.info" />
          </li>
          <li>
            <span className="docs-page-bold-text text-rarity-5">
              {t("docsPage.ChestUpBoatOut.Legendary")}
            </span>
            <Trans i18nKey="docsPage.ChestUpBoatOut.Legendary.info" />
          </li>
          <li>
            <span className="docs-page-bold-text text-rarity-6">
              {t("docsPage.ChestUpBoatOut.Mythical")}
            </span>
            <Trans i18nKey="docsPage.ChestUpBoatOut.Mythical.info" />
          </li>
        </ol>
      </Box>
      <Divider
        orientation="horizontal"
        variant="middle"
        flexItem
        sx={{ margin: 0, borderWidth: "1px" }}
      />
      <Box className="docs-page-content-box">
        <Typography variant="h1" sx={{ textAlign: "left" }}>
          {t("docsPage.PlayToEarn.title")}
        </Typography>
        <Box sx={{ mx: "auto" }}>
          <LazyLoadImage
            src={IdleFishingImages.IdleFishingPlayToEarn}
            alt="mint"
            effect="blur"
            className="doc-page-play-to-earn-image hover-effect"
          />
        </Box>
        <Typography variant="h4" sx={{ textAlign: "left" }}>
          {t("docsPage.PlayToEarn.1")}
        </Typography>
        <ol className="docs-page-disc-list docs-page-mt-[-1rem]">
          <li>{t("docsPage.PlayToEarn.1.info")}</li>
        </ol>
        <Typography variant="h4" sx={{ textAlign: "left" }}>
          {t("docsPage.PlayToEarn.2")}
        </Typography>
        <ol className="docs-page-disc-list docs-page-mt-[-1rem]">
          <li>
            {t("docsPage.PlayToEarn.2.info")}
            <span className="docs-page-bold-text">$FishUP</span>
          </li>
        </ol>
        <Typography variant="h4" sx={{ textAlign: "left" }}>
          {t("docsPage.PlayToEarn.3")}
        </Typography>
        <ol className="docs-page-disc-list docs-page-mt-[-1rem]">
          <li>
            <Trans i18nKey="docsPage.PlayToEarn.3.info" />
          </li>
        </ol>
        <Typography variant="h4" sx={{ textAlign: "left" }}>
          {t("docsPage.PlayToEarn.4")}
        </Typography>
        <ol className="docs-page-disc-list docs-page-mt-[-1rem]">
          <li>
            <Trans i18nKey="docsPage.PlayToEarn.4.info" />
          </li>
        </ol>
        <Typography variant="h4" sx={{ textAlign: "left" }}>
          {t("docsPage.PlayToEarn.5")}
        </Typography>
        <ol className="docs-page-disc-list docs-page-mt-[-1rem]">
          <li>
            <Trans i18nKey="docsPage.PlayToEarn.5.info" />
          </li>
        </ol>
        <Typography variant="h4" sx={{ textAlign: "left" }}>
          {t("docsPage.PlayToEarn.6")}
        </Typography>
        <ol className="docs-page-disc-list docs-page-mt-[-1rem]">
          <li>
            <Trans i18nKey="docsPage.PlayToEarn.6.info" />
          </li>
        </ol>
        <Typography variant="h4" sx={{ textAlign: "left" }}>
          {t("docsPage.PlayToEarn.7")}
        </Typography>
        <ol className="docs-page-disc-list docs-page-mt-[-1rem]">
          <li>
            <Trans i18nKey="docsPage.PlayToEarn.7.info" />
          </li>
        </ol>
      </Box>
      <Divider
        orientation="horizontal"
        variant="middle"
        flexItem
        sx={{ margin: 0, borderWidth: "1px" }}
      />
      <Box className="docs-page-content-box">
        <Typography variant="h1" sx={{ textAlign: "left" }}>
          {t("docsPage.Tutorial.title")}
        </Typography>
        <Box sx={{ mx: "auto" }}>
          <LazyLoadImage
            src={IdleFishingImages.IdleFishingGetBoardTutorial}
            alt="get board"
            effect="blur"
            className="docs-page-get-board-image hover-effect"
          />
        </Box>
        <br />
        <Box sx={{ mx: "auto" }}>
          <LazyLoadImage
            src={IdleFishingImages.IdleFishingEquipBoardTutorial}
            alt="equip board"
            effect="blur"
            className="docs-page-equip-board-image hover-effect"
          />
        </Box>
        <br />
        <Box sx={{ mx: "auto" }}>
          <LazyLoadImage
            src={IdleFishingImages.IdleFishingWithdraw}
            alt="withdraw"
            effect="blur"
            className="docs-page-withdraw-image hover-effect"
          />
        </Box>
      </Box>
      <Divider
        orientation="horizontal"
        variant="middle"
        flexItem
        sx={{ margin: 0, borderWidth: "1px" }}
      />
      <Box className="docs-page-content-box">
        <Typography variant="h1" sx={{ textAlign: "left" }}>
          {t("docsPage.Tokenomics.title")}
        </Typography>
        <Typography variant="h4" sx={{ textAlign: "left" }}>
          {t("docsPage.Tokenomics.subTitle")}
        </Typography>
        <ol className="docs-page-disc-list docs-page-mt-[-1rem]">
          <li>
            {t("docsPage.Tokenomics.info1")}
            <span className="docs-page-bold-text">FishUP</span>
          </li>
          <li>
            {t("docsPage.Tokenomics.info2")}
            <span className="docs-page-bold-text">TON</span>
          </li>
        </ol>
        <Box sx={{ mx: "auto" }}>
          <LazyLoadImage
            src={IdleFishingImages.IdleFishingTokenomics}
            alt="sell"
            effect="blur"
            className="doc-page-tokenomics-image hover-effect"
          />
        </Box>
      </Box>
      <Typography
        sx={{
          marginTop: "7rem",
          fontSize: "1.25rem",
          textAlign: "center",
          lineHeight: "1.75rem",
          paddingBottom: "1rem",
        }}
      >
        © Idle Fishing. All Rights Reserved.
      </Typography>
    </Container>
  );
};

export default IdleFishingDocsPage;
