const STORAGE_KEY_PREFIX = "IF_";


export const STORAGE_KEY_LANGUAGE_CODE = `${STORAGE_KEY_PREFIX}LANGUAGE_CODE`


// Social Links
export const XChannel = "https://x.com/IdleFishing_P2E";
export const TelegramChat = "https://t.me/+anuMAL-7jlplNjk1";
export const TelegramAnnouncement = "https://t.me/idlefishing";
export const LinkTree = "https://linktr.ee/idlefishing.official";
export const SwapLink = "https://app.ston.fi/swap?chartVisible=false&ft=TON&tt=EQDH6F8bXL_ZQtT9zfYZinT2NhsrcXa1VI5koO98slz8rNjs"